import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store";

//Adding layouts router.
const BlankLayout = () => import("@/layouts/BlankLayout")
const Layout = () => import("@/layouts/backend/Layout")

//Adding page content router.
const Dashbord = () => import('@/views/Dashboard/Dashbord')

const Notif = () => import('@/views/Notif/List')

//Verifikasi
const Verif = () => import('@/views/VerifikasiSPT/Verifikasi')

//auth elements
const Login = () => import('@/views/Auth/Login')
const RecoverPassword = () => import('@/views/Auth/RecoverPassword')

const AnggaranList = () => import('@/views/Master/Anggaran/Lists')
const AnggaranAdd = () => import('@/views/Master/Anggaran/Add')
const AnggaranEdit = () => import('@/views/Master/Anggaran/Edit')

const PegawaiList = () => import('@/views/Master/Pegawai/Lists')
const PegawaiEdit = () => import('@/views/Master/Pegawai/Edit')
const PegawaiAdd = () => import('@/views/Master/Pegawai/Add')

const PejabatList = () => import('@/views/Master/Pejabat/List')
const PejabatEdit = () => import('@/views/Master/Pejabat/Edit')
const PejabatAdd = () => import('@/views/Master/Pejabat/Add')

const DalamDaerahList = () => import('@/views/Master/DalamDaerah/List')
const DalamDaerahEdit = () => import('@/views/Master/DalamDaerah/Edit')
const DalamDaerahAdd = () => import('@/views/Master/DalamDaerah/Add')

const ProfileEdit = () => import('@/views/Profile/Edit')

const UserList = () => import('@/views/Config/User/Lists')
const UserEdit = () => import('@/views/Config/User/Edit')
const UserAdd = () => import('@/views/Config/User/Add')

const PeranList = () => import('@/views/Config/Peran/Lists')
const PeranEdit = () => import('@/views/Config/Peran/Edit')
const PeranAdd = () => import('@/views/Config/Peran/Add')

const JenisTransportList = () => import('@/views/Pendukung/JenisTransport/List')
const JenisTransportEdit = () => import('@/views/Pendukung/JenisTransport/Edit')
const JenisTransportAdd = () => import('@/views/Pendukung/JenisTransport/Add')

const SatuanList = () => import('@/views/Pendukung/SatuanPengeluaran/List')
const SatuanEdit = () => import('@/views/Pendukung/SatuanPengeluaran/Edit')
const SatuanAdd = () => import('@/views/Pendukung/SatuanPengeluaran/Add')

const KategoriPengeluaranList = () => import('@/views/Pendukung/KategoriPengeluaran/List')
const KategoriPengeluaranEdit = () => import('@/views/Pendukung/KategoriPengeluaran/Edit')
const KategoriPengeluaranAdd = () => import('@/views/Pendukung/KategoriPengeluaran/Add')

const WilayahIndex = () => import('@/views/Master/Wilayah/Index')

//
const SPTList = () => import('@/views/SPT/List')
const SPTAdd = () => import('@/views/SPT/Add')
const SPTEdit = () => import('@/views/SPT/Edit')

// SPT Log
const SPTLog = () => import('@/views/SPT/Log')

const SPPDList = () => import('@/views/SPPD/List')
const SPPDEdit = () => import('@/views/SPPD/Edit')

const ReportRekap = () => import('@/views/Report/Rekap')
const ReportPegawai = () => import('@/views/Report/Pegawai')
const ReportAnggaran = () => import('@/views/Report/Anggaran')

Vue.use(VueRouter)

const childReport = () => [
  {
    path: 'laporan/rekapitulasi-tahunan',
    name: 'report.Rekap',
    meta: { name : 'Rekap', can: 'laporan-tahunan', requiresAuth: true},
    component: ReportRekap
  },
  {
    path: 'laporan/anggaran',
    name: 'report.Anggaran',
    meta: { name : 'ReportAnggaran', can: 'laporan-anggaran', requiresAuth: true},
    component: ReportAnggaran
  },
  {
    path: 'laporan/pegawai',
    name: 'report.Pegawai',
    meta: { name : 'ReportPegawai', can: 'laporan-pegawai', requiresAuth: true},
    component: ReportPegawai
  },
]

const childMaster = () => [
  {
    path: 'anggaran',
    name: 'Master.anggaran',
    meta: { name : 'AnggaranList', can: 'anggaran-view', requiresAuth: true},
    component: AnggaranList
  },
  {
    path: 'anggaran/edit/:id',
    name: 'Edit.anggaran',
    meta: { name : 'AnggaranEdit', can: 'anggaran-edit', requiresAuth: true},
    component: AnggaranEdit
  },
  {
    path: 'anggaran/add',
    name: 'Add.anggaran',
    meta: { name : 'AnggaranAdd', can: 'anggaran-add', requiresAuth: true},
    component: AnggaranAdd
  },
  {
    path: 'pejabat',
    name: 'Master.pejabat',
    meta: { name : 'PejabatList', can: 'pejabat-view', requiresAuth: true},
    component: PejabatList
  },
  {
    path: 'pejabat/edit/:id',
    name: 'Edit.pejabat',
    meta: { name : 'PejabatAdd',can: 'pejabat-edit', requiresAuth: true},
    component: PejabatEdit
  },
  {
    path: 'pejabat/add',
    name: 'Add.pejabat',
    meta: { name : 'PejabatAdd', can: 'pejabat-add', requiresAuth: true},
    component: PejabatAdd
  },
  {
    path: 'pegawai',
    name: 'Master.pegawai',
    meta: { name : 'PegawaiList', can: 'pegawai-view', requiresAuth: true},
    component: PegawaiList
  },
  {
    path: 'pegawai/edit/:id',
    name: 'Edit.pegawai',
    meta: { name : 'Master Pegawai', can: 'pegawai-edit', requiresAuth: true},
    component: PegawaiEdit
  },
  {
    path: 'pegawai/add',
    name: 'Add.pegawai',
    meta: { name : 'Master Pegawai', can: 'pegawai-add', requiresAuth: true},
    component: PegawaiAdd
  }, {
    path: 'wilayah',
    name: 'Master.wilayah',
    meta: { name : 'WilayahIndex', requiresAuth: true},
    component: WilayahIndex
  },{
    path: 'daerah',
    name: 'Master.dalamDaerah',
    meta: { name : 'DalamDaerahList', requiresAuth: false},
    component: DalamDaerahList
  },
  {
    path: 'daerah/edit/:id',
    name: 'DalamDaerahEdit',
    meta: { name : 'DalamDaerahEdit', requiresAuth: false},
    component: DalamDaerahEdit
  },
  {
    path: 'daerah/add',
    name: 'DalamDaerahAdd',
    meta: { name : 'DalamDaerahAdd', requiresAuth: false},
    component: DalamDaerahAdd
  },
];

const childRoute = () => [
  {
    path: '/profile',
    name: 'user-profile',
    meta: { name: 'user-profile', can: 'true', requiresAuth: true },
    component: ProfileEdit
  },
  ///////////////////////////////////////////
  {
    path: '',
    name: 'layout.dashboard',
    meta: {  name: 'Dashboard', can: 'true', requiresAuth: true },
    component: Dashbord
  },
  {
    path: 'notif',
    name: 'notif',
    meta: {  name: 'Notif', can: 'true', requiresAuth: true },
    component: Notif
  },
  {
    path: 'spt',
    name: 'SPT.data',
    meta: { name: 'SPTList', can: 'spt-view', requiresAuth: true },
    component: SPTList
  },
  {
    path: 'spt/log',
    name: 'SPT.log',
    meta: { name: 'SPTLog', can: 'spt-log', requiresAuth: true },
    component: SPTLog
  },
  {
    path: 'spt/add',
    name: 'SPT.Add',
    meta: { name: 'SPTAdd', can: 'spt-add', requiresAuth: true },
    component: SPTAdd
  },
  {
    path: 'spt/:id/edit',
    name: 'SPT.Edit',
    meta: { name: 'SPTEdit', can: 'spt-edit', requiresAuth: true },
    component: SPTEdit
  },
  {
    path: 'spt/:id/sppd',
    name: 'SPPD.data',
    meta: { name: 'SPPDList' },
    component: SPPDList
  },
  {
    path: 'spt/:id/sppd/:sppd_id/:pegawai_id',
    name: 'SPPD.Edit',
    meta: { name: 'SPPDEdit' },
    component: SPPDEdit
  },
]
const authchildRoute = () =>[
  {
    path: 'recover-password',
    name: 'auth.recover-password',
    meta: {  name: 'Recover Password' },
    component: RecoverPassword
  }
]
const childConfig = () => [
  {
    path: 'peran',
    name: 'Config.peran',
    meta: { name : 'PeranList', can: 'peran-view', requiresAuth: true},
    component: PeranList
  },
  {
    path: 'peran/edit/:id',
    name: 'Config.editPeran',
    meta: { name : 'PeranEdit', can: 'peran-edit', requiresAuth: true},
    component: PeranEdit
  },
  {
    path: 'peran/add',
    name: 'Config.addPeran',
    meta: { name : 'PeranAdd', can: 'peran-add', requiresAuth: true},
    component: PeranAdd
  },
  {
    path: 'user',
    name: 'Config.user',
    meta: { name : 'UserList', can: 'user-view', requiresAuth: true},
    component: UserList
  },
  {
    path: 'user/edit/:id',
    name: 'Config.editUser',
    meta: { name : 'EditUser', can: 'user-edit', requiresAuth: true},
    component: UserEdit
  },
  {
    path: 'user/add',
    name: 'Config.addUser',
    meta: { name : 'AddUser', can: 'user-add', requiresAuth: true},
    component: UserAdd
  },
]
const childPendukung = () => [
  {
    path: 'kategori-pengeluaran',
    name: 'Pendukung.KategoriPengeluaranList',
    meta: { name : 'KategoriPengeluaranList', requiresAuth: false},
    component: KategoriPengeluaranList
  },
  {
    path: 'kategori-pengeluaran/edit/:id',
    name: 'Pendukung.KategoriPengeluaranEdit',
    meta: { name : 'KategoriPengeluaranEdit', requiresAuth: false},
    component: KategoriPengeluaranEdit
  },
  {
    path: 'kategori-pengeluaran/add',
    name: 'PendukungKategoriPengeluaranAdd',
    meta: { name : 'KategoriPengeluaranAdd', requiresAuth: false},
    component: KategoriPengeluaranAdd
  },
  {
    path: 'jenis-transport',
    name: 'Pendukung.JenisTransport',
    meta: { name : 'JenisTransportList', requiresAuth: false},
    component: JenisTransportList
  },
  {
    path: 'jenis-transport/edit/:id',
    name: 'Pendukung.JenisTransportEdit',
    meta: { name : 'JenisTransportEdit', requiresAuth: false},
    component: JenisTransportEdit
  },
  {
    path: 'jenis-transport/add',
    name: 'Pendukung.JenisTransportAdd',
    meta: { name : 'JenisTransportAdd', requiresAuth: false},
    component: JenisTransportAdd
  },
  {
    path: 'satuan',
    name: 'Pendukung.satuan',
    meta: { name : 'SatuanList', requiresAuth: false},
    component: SatuanList
  },
  {
    path: 'satuan/edit/:id',
    name: 'Pendukung.SatuanAdd',
    meta: { name : 'SatuanAdd', requiresAuth: false},
    component: SatuanEdit
  },
  {
    path: 'satuan/add',
    name: 'Pendukung.addUser',
    meta: { name : 'SatuanAdd', requiresAuth: false},
    component: SatuanAdd
  },
]

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Layout,
    children: childRoute()
  },
  {
    path: '/auth',
    name: 'auth',
    component: BlankLayout,
    children: authchildRoute()
  },
  {
    path: '/master',
    name: 'Master',
    component: Layout,
    children: childMaster()
  },
  {
    path: '/report',
    name: 'Report',
    component: Layout,
    children: childReport()
  },
  {
    path: '/config',
    name: 'Configuration',
    component: Layout,
    children: childConfig()
  },
  {
    path: '/pendukung',
    name: 'Pendukung',
    component: Layout,
    children: childPendukung()
  },
  {
    path: '/login',
    name: 'Login',
    meta: {  name: 'Login', guest: true },
    component: Login
  },
  {
    path: '/verifikasi',
    name: 'Verifikasi',
    meta: {  name: 'Verifikasi', guest: true },
    component: Verif
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to.meta.can, to)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // console.log(2,store.getters.isAuthenticated)
    if (store.getters.isAuthenticated) {
      if (to.meta.can == 'true') {
        next();
        return;
      } else {
        const perms = store.getters.StatePerms
        if (perms.includes(to.meta.can) || perms.includes('is_admin')){
          next();
          return;
        } else {
          next("/");
        }
      }
      // return;
    }
    next("/login");
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router
