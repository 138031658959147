
import axios from 'axios'
import store from '../store'
import config from './config'
import { showToast } from '../_utils/toast'

// import { mapGetters } from "vuex";

axios.defaults.withCredentials = true
const CancelToken = axios.CancelToken
export let cancel
// axios.defaults.baseURL = 'http://localhost/tesmart/';

const instance = axios.create({
  baseURL: config.api_endpoint,
  timeout: 20000,
  cancelToken: new CancelToken(function executor(c) {
    cancel = c;
  })
});

instance.interceptors.request.use((config) => {
  const token = store.getters.StateToken
  config.headers.authorization = `Bearer ${token}`;
  config.headers.accept = 'application/json'
  config.headers['Content-Type'] = 'application/json'

  return config;
});

instance.interceptors.response.use((response) => {
  return response;
}, function (error) {
  if (error) {
    // console.log(error, error.config._retry)
    // const originalRequest = error.config;
    if (error.response.status === 401 ) {
      window.localStorage.clear()
      window.location = '/'
    }
    
    if(error.response.data.messages){
      showToast('error', error.response.data.messages[0])
    } else {
      showToast('error', 'Kesalahan! Tidak dapat memproses.')
    }
  } else {
    return Promise.reject(error);
  }
});

// axios.interceptors.response.use(undefined, function(error) {
//   console.log('err', error)
//   if (error) {
//     const originalRequest = error.config;
//     console.log('refresh', error.response.status, originalRequest._retry)
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       store.dispatch("LogOut");
//       return router.push("/login");
//     }
//   }
// });

export default instance