import Vue from 'vue'

Vue.component('card', require('../components/cards/card').default)
Vue.component('Progressbar', require('../components/progressbar/Progressbar').default)
// Vue.component('Slick', require('../components/slider/Slick').default)
Vue.component('tab-nav', require('../components/tab/tab-nav').default)
Vue.component('tab-nav-items', require('../components/tab/tab-nav-items').default)
Vue.component('tab-content', require('../components/tab/tab-content').default)
Vue.component('tab-content-item', require('../components/tab/tab-content-item').default)
// Vue.component('HoverMenu', require('../components/menustyle/HoverMenu').default)
Vue.component('ModeSwitch', require('../components/mode/ModeSwitch').default)

Vue.component('Photoswipe', require('../components/photoswipe/Photoswipe').default)
