module.exports = {
  app_version: '0.0.1',
  base_routing: '/',
  url_img: 'https://sppd.disdikkerinci.id',
  endpoint: 'https://api.sppd.disdikkerinci.id/',
  api_endpoint: 'https://api.sppd.disdikkerinci.id/api'
  // url_img: 'http://dikjarspd.local',
  // endpoint: 'http://dikjarspd.local/',
  // api_endpoint: 'http://dikjarspd.local/api'

  
  // url_img: 'https://dev.disdikkerinci.id',
  // endpoint: 'https://apidev.sppd.disdikkerinci.id/',
  // api_endpoint: 'https://apidev.sppd.disdikkerinci.id/api'
 }