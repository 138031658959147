//store/modules/auth.js
import config from '../../config/config'
import instance from '../../config/axios'
import Vue from 'vue'

const state = {
  user: null,
  token: null,
  perms: null,
  appName: 'SPPD Disdik Kerinci',
  logo: require('../../assets/images/logo_sppd.png'),
  darklogo:require('../../assets/images/logo_sppd.png'),
  dark: false,
  namespaced: true,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  StateUser: (state) => state.user,
  StateToken: (state) => state.token,
  StatePerms: (state) => state.perms,

  appName: state => { return state.appName },
  logo: state => { return state.logo },
  darklogo: state => { return state.darklogo },
  image1: state => { return state.user.image },
  name: state => { return state.user.name },
  image2:state => { return state.user.image2},
  image3:state => { return state.user.image3},
  dark: state => { return state.dark },
};

const actions = {
  async handleLogin({commit}, user){
    let dataUser, token, perms
    const sanctum = config.endpoint + 'sanctum/csrf-cookie'
    await instance.get(sanctum).then( () => {})

    await instance.post('/login', user).then(response => {
      if(response.data.success){
        Vue.$toast.success('Berhasil Login!')
        token = response.data.data.token
        let data = response.data.data
        dataUser = {
          fullName: data.full_name,
          pegawaiId: data.pegawai_id,
          pathFoto: data.path_foto,
          email: data.email
        }
        perms = data.perms
      } else {
        Vue.$toast.error(response.data.messages[0],{})
      }
    })
    
    await commit("setToken", token)
    await commit("setPermission", perms)
    await commit("setUser", dataUser)
  },

  async LogOut({ commit }) {
    await instance.post('logout')
    commit("logout", null, null);
  },

  layoutModeAction (context, payload) {
    context.commit('layoutModeCommit', payload.dark)
  }
};

const mutations = {
  setUser(state, username) {
    state.user = username;
  },

  setPermission(state, perms) {
    state.perms = perms;
  },

  setToken(state, token) {
    state.token = token;
  },

  logout(state, user, token) {
    state.user = user;
    state.token = token;
  },

  layoutModeCommit (state, payload) {
    state.dark = payload
    if (!payload) {
      state.logo = require('../../assets/images/logo_sppd.png')
    } else {
      state.logo = require('../../assets/images/logo_sppd.png')
    }
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};